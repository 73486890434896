import { IOrderClientData, OrderType, ProductType } from "../../types";
import { jsonParseString } from "../../utils/string";
import { ISalesChannel } from "../ecommerceApi/SalesChannel/types";
import { salesChannel as globalSalesChannelId } from "../../config";
import { ClientAction } from "../../store/order";

export const gtmHandleDataLayer = (
    orderClientData: Partial<IOrderClientData>,
    salesChannel?: ISalesChannel,
    currencyCode?: string
) => {
    const dataLayer = window?.["dataLayer"];

    if (!dataLayer) return;

    const data =
        orderClientData.parsed || (orderClientData.data ? JSON.parse(orderClientData.data) : {});

    const order: OrderType | undefined = data.order || undefined;
    const product: ProductType | undefined = data.product || undefined;

    switch (orderClientData.action) {
        case "identify":
            dataLayer.push({
                event: "identify",
                userData: {
                    $email: data?.email,
                    $first_name: data?.first_name,
                    $last_name: data?.last_name
                }
            });
            break;
        case "addToCart":
            if (order) {
                dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        transaction_id: order.number,
                        value: order.totalAmount,
                        currency: currencyCode,
                        items: mapItems(order, salesChannel, currencyCode)
                    }
                });
            }
            break;
        case "removeFromCart":
            if (order && salesChannel) {
                dataLayer.push({
                    event: "remove_from_cart",
                    ecommerce: {
                        transaction_id: order.number,
                        value: order.totalAmount,
                        currency: currencyCode,
                        items: mapItems(order, salesChannel, currencyCode)
                    }
                });
            }
            break;
        case "viewCart":
            if (order && salesChannel) {
                dataLayer.push({
                    event: "view_cart",
                    ecommerce: {
                        transaction_id: order.number,
                        value: order.totalAmount,
                        currency: currencyCode,
                        items: mapItems(order, salesChannel, currencyCode)
                    }
                });
            }
            break;
        case "startedCheckout":
        case "beginCheckout":
            if (order) {
                dataLayer.push({
                    event: "begin_checkout",
                    ecommerce: {
                        transaction_id: order.number,
                        value: order.totalAmount,
                        currency: currencyCode,
                        coupon: (order?.couponsUsed ?? [])?.map(el => el.couponCode).join(","),
                        items: mapItems(order, salesChannel, currencyCode)
                    }
                });
            }
            break;
        case "addPaymentInfo":
            if (order && salesChannel) {
                dataLayer.push({
                    event: "add_payment_info",
                    ecommerce: {
                        value: order.totalAmount,
                        currency: currencyCode,
                        coupon: (order?.couponsUsed ?? [])?.map(el => el.couponCode).join(","),
                        items: mapItems(order, salesChannel, currencyCode)
                    }
                });
            }
            break;
        case "addShippingInfo":
            if (order && salesChannel) {
                dataLayer.push({
                    event: "add_shipping_info",
                    ecommerce: {
                        value: order.totalAmount,
                        currency: currencyCode,
                        coupon: (order?.couponsUsed ?? [])?.map(el => el.couponCode).join(","),
                        items: mapItems(order, salesChannel, currencyCode)
                    }
                });
            }
            break;
        case "purchase":
            if (order) {
                dataLayer.push({
                    event: "purchase",
                    ecommerce: {
                        transaction_id: order.number,
                        value: order.totalAmount,
                        tax: order.totalTax,
                        shipping: order.shippingAmount,
                        currency: currencyCode,
                        coupon: (order?.couponsUsed ?? [])?.map(el => el.couponCode).join(","),
                        items: mapItems(order, salesChannel, currencyCode)
                    }
                });
            }
            break;
        case "viewItem":
            if (salesChannel && product) {
                const schema = jsonParseString(product?.schema ?? "{}");

                const seo = Array.isArray(schema)
                    ? schema.find(s => s.salesChannelId === salesChannel.id)
                    : schema;

                dataLayer.push({
                    event: "view_item",
                    ecommerce: {
                        currency: currencyCode,
                        valye: product.currentPrice,
                        items: [
                            {
                                item_id: product.id || product.SKUCode,
                                item_name: product.SKUName,
                                item_brand: seo.brand,
                                item_category: seo.category,
                                item_variant: product.shortName,
                                price: product.currentPrice,
                                currency: currencyCode,
                                quantity: 1
                            }
                        ]
                    }
                });
            }
            break;
        case ClientAction.videoPlay:
            dataLayer.push({
                event: ClientAction.videoPlay
            });
            break;
        case "addToCartLink":
            const cartLinkId = data.cartLinkId;
            dataLayer.push({
                event: "addToCartLink",
                cartLinkId
            });
            break;
    }
};

const mapItems = (
    order: OrderType,
    salesChannel: ISalesChannel | undefined,
    currencyCode: string | undefined
) => {
    return order.orderItems
        .filter(oi => !oi.parentId)
        .map(item => {
            const seoData = getSeoData(item, salesChannel);

            return {
                item_id: item.code || item.skuId,
                item_name: item.name,
                affiliation: salesChannel?.name,
                index: 0,
                price: item.unitAmount,
                tax: item.totalTaxAmount,
                quantity: item.quantity,
                item_brand: seoData?.brand,
                item_category: 0,
                item_variant: item.shortName,
                currency: currencyCode,
                discount: item.totalDiscount,
                coupon: (order?.couponsUsed || [])?.map(el => el.couponCode).join(",")
            };
        });
};
const getSeoData = (item: any, salesChannel?: ISalesChannel): Record<string, any> | undefined => {
    const schema = JSON.parse(item?.schema || "{}");

    const isArray = Array.isArray(schema);

    if (!isArray) {
        return schema || {};
    }

    const seoData: Record<string, any> | undefined =
        schema.find(s => s.salesChannelId === salesChannel?.id) || schema?.[0];

    return seoData;
};
