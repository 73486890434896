import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CognitoService from "../../services/cognito";
import { AddressType, CustomerType } from "../../types";
import EcommerceApi from "../../services/ecommerceApi";

const NAME = "auth";

export type AppCognitoUser = {
    username: string;
    email: string;
    given_name: string;
    family_name: string;
    phone_number: string;
    sub: string;
};

const initialState: {
    user?: AppCognitoUser | null;
    customer: CustomerType | null;
    addresses: AddressType[];
} = {
    customer: null,
    addresses: []
};

export const getUser = createAsyncThunk(NAME + "/getUser", async () => {
    const data = await CognitoService.getCognitoUser();
    return data;
});

export const getCustomerAddresses = createAsyncThunk(
    NAME + "/getAddresses",
    async () => {
        const data = await EcommerceApi.getCustomerAddresses();
        return data;
    }
);

type orderStateType = typeof initialState;

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateCustomer: (state: orderStateType, action) => {
            state.customer = action.payload || null;
            return state;
        }
    },
    extraReducers: builder => {
        builder.addCase(getUser.fulfilled, (state: orderStateType, action) => {
            state.user = action.payload;
        });
        builder.addCase(getCustomerAddresses.fulfilled, (state: orderStateType, action) => {
            state.addresses = action.payload;
        });
    }
});
export const { updateCustomer } = authSlice.actions;

export default authSlice.reducer;
