import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CountryType, OrderType } from "../../types";
import EcommerceApi from "../../services/ecommerceApi";

const NAME = "country";

export const fetchAllContries = createAsyncThunk(NAME + "/getCountries", async () => {
    let data = await EcommerceApi.getCountries();

    data = data.sort((a: CountryType, b: CountryType) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
    });

    return data;
});

const initialState: {
    countries: CountryType[];
} = {
    countries: []
};
type countryStateType = typeof initialState;
const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAllContries.fulfilled, (state: countryStateType, action) => {
            state.countries = action.payload;
        });
    }
});

export default orderSlice.reducer;
