import { ClientAction } from "../../store/order";
import { IOrderClientData, OrderType } from "../../types";
import { jsonParseString } from "../../utils/string";

export const klaviyoHandleClientData = (orderClientData: Partial<IOrderClientData>) => {
    const klaviyo = window?.["_learnq"];
    const klOnsite = window?.["_klOnsite"];

    if (!klaviyo) return;

    const data =
        orderClientData.parsed || (orderClientData.data ? JSON.parse(orderClientData.data) : {});

    switch (orderClientData.action) {
        case ClientAction.openForm:
            if (klOnsite) {
                klOnsite.push(["openForm", data.formId]);
                klaviyo.push(["track", "Form Viewed", data]);
            }
            break;
        case ClientAction.addToCart:
            klaviyo.push(["track", "Added to Cart", data]);
            break;
        case ClientAction.identify:
            klaviyo.push([
                "identify",
                {
                    $email: data?.email,
                    $first_name: data?.first_name,
                    $last_name: data?.last_name
                }
            ]);
            break;
        case ClientAction.startedCheckout:
            let cart = data as OrderType;

            const unixtimestamp = Math.floor(Date.now() / 1000);

            const orderItems = cart?.orderItems.filter(oi => !oi.parentId) || [];

            if (orderItems.length < 1) return;

            klaviyo.push([
                "track",
                "Started Checkout",
                {
                    $event_id: cart.id + "_" + unixtimestamp,
                    $value: cart.totalAmount,
                    CheckoutURL: window.location.href,
                    ItemNames: orderItems.map(item => item.name),
                    Items: orderItems.map(item => {
                        const file = jsonParseString(item.imageUrl);
                        const imageUrl = file?.url || "";

                        return {
                            ProductID: item.skuId,
                            ProductName: item.name,
                            SKU: item.code,
                            ImageURL: imageUrl,
                            ProductURL: item.productUrl,
                            ItemPrice: item.unitAmount,
                            Quantity: item.quantity,
                            RowTotal: item.totalAmount
                        };
                    })
                }
            ]);
            break;
        case ClientAction.videoPlay:
            klaviyo.push(["track", "Video Play"]);
            break;
    }
};
