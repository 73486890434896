export const getSiteKeys = () => {
    const GQL_ENDPOINT = process.env.GATSBY_APP_SYNC_GQL as string;
    const GQL_API_KEY = process.env.GATSBY_APP_SYNC_KEY as string;
    const cyclicKey = String(process.env.GATSBY_CYCLIC_KEY).toUpperCase();
    const keyPartitions = cyclicKey.split("-");
    const tenant = keyPartitions[0];
    const salesChannel = keyPartitions[1];
    return {
        GQL_ENDPOINT,
        GQL_API_KEY,
        tenant,
        salesChannel
    };
};
